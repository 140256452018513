import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { validateSchema } from 'utils/zod'
import {
  PaginatedCustomTokenUserList,
  PaginatedCustomTokenUsersSearch,
  customTokenUser,
  customTokenUsersSearch,
  customTokenTypeList,
  CustomTokenTypeList,
  PaginatedCustomTokenPayoutList,
  customTokenPayoutSchema,
  PaginatedCustomTokenPayoutUserList,
  customTokenPayoutUserSchema,
  CustomTokenPayout,
  CustomTokenUser,
} from 'types/token/customToken'
import { QueryPagination } from 'types/common'

interface UseCustomTokenUsersSearchProps<TData> {
  tokenId: string
  searchTerm?: string
  options?: Omit<UseQueryOptions<PaginatedCustomTokenUsersSearch, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenUsersSearch = <TData = PaginatedCustomTokenUsersSearch>({
  tokenId,
  searchTerm,
  options,
}: UseCustomTokenUsersSearchProps<TData>) => {
  return useQuery<PaginatedCustomTokenUsersSearch, ErrorResponse, TData>({
    queryKey: ['custom-token-users-search', tokenId, searchTerm],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenSearchUsers(tokenId), {
        params: {
          q: searchTerm,
        },
      })
      const data = response.data
      return validateSchema(customTokenUsersSearch.array(), data, 'useCustomTokenUsersSearch')
    },
    ...options,
  })
}

interface UseCustomTokenUserListProps<TData> {
  tokenId: string
  searchTerm?: string
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedCustomTokenUserList, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenUserList = <TData = PaginatedCustomTokenUserList>({
  tokenId,
  searchTerm,
  pagination,
  options,
}: UseCustomTokenUserListProps<TData>) => {
  return useQuery<PaginatedCustomTokenUserList, ErrorResponse, TData>({
    queryKey: [
      'custom-token-user-list',
      tokenId,
      searchTerm,
      pagination?.page,
      pagination?.pageSize,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenUserList(tokenId), {
        params: {
          q: searchTerm,
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(customTokenUser.array(), data, 'useCustomTokenUserList')
    },
    ...options,
  })
}

interface UseCustomTokenTypesProps<TData> {
  options?: Omit<UseQueryOptions<CustomTokenTypeList, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenTypes = <TData = CustomTokenTypeList>({
  options,
}: UseCustomTokenTypesProps<TData>) => {
  return useQuery<CustomTokenTypeList, ErrorResponse, TData>({
    queryKey: ['custom-token-type-list'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customType())
      const data = response.data
      return validateSchema(customTokenTypeList, data, 'useCustomTokenTypes')
    },
    ...options,
  })
}

interface UseCustomTokenPayoutListProps<TData> {
  tokenId: string
  isPreview: boolean
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedCustomTokenPayoutList, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenPayoutList = <TData = PaginatedCustomTokenPayoutList>({
  tokenId,
  isPreview,
  pagination,
  options,
}: UseCustomTokenPayoutListProps<TData>) => {
  return useQuery<PaginatedCustomTokenPayoutList, ErrorResponse, TData>({
    queryKey: [
      'custom-token-payout-list',
      tokenId,
      isPreview,
      pagination?.page,
      pagination?.pageSize,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenPayout(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          preview: isPreview,
        },
      })
      const data = response.data
      return validateSchema(customTokenPayoutSchema.array(), data, 'useCustomTokenPayoutList')
    },
    ...options,
  })
}

interface UseCustomTokenPayoutDetailsProps<TData> {
  tokenId: string
  payoutId: number
  options?: Omit<UseQueryOptions<CustomTokenPayout, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenPayoutDetails = <TData = CustomTokenPayout>({
  tokenId,
  payoutId,
  options,
}: UseCustomTokenPayoutDetailsProps<TData>) => {
  return useQuery<CustomTokenPayout, ErrorResponse, TData>({
    queryKey: ['custom-token-payout', tokenId, payoutId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenPayoutById(tokenId, payoutId))
      const data = response.data
      return validateSchema(customTokenPayoutSchema, data, 'useCustomTokenPayoutDetails')
    },
    ...options,
  })
}

interface UseCustomTokenPayoutUserListProps<TData> {
  payoutId: number
  pagination?: QueryPagination
  options?: Omit<
    UseQueryOptions<PaginatedCustomTokenPayoutUserList, ErrorResponse, TData>,
    'queryKey'
  >
}

export const useCustomTokenPayoutUserList = <TData = PaginatedCustomTokenPayoutUserList>({
  payoutId,
  pagination,
  options,
}: UseCustomTokenPayoutUserListProps<TData>) => {
  return useQuery<PaginatedCustomTokenPayoutUserList, ErrorResponse, TData>({
    queryKey: ['custom-token-payout-user-list', payoutId, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenPayoutUsers(payoutId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(
        customTokenPayoutUserSchema.array(),
        data,
        'useCustomTokenPayoutUserList',
      )
    },
    ...options,
  })
}

interface UseCustomTokenUserProps<TData> {
  tokenId: string
  userId: number
  options?: Omit<UseQueryOptions<CustomTokenUser, ErrorResponse, TData>, 'queryKey'>
}

export const useCustomTokenUser = <TData = CustomTokenUser>({
  tokenId,
  userId,
  options,
}: UseCustomTokenUserProps<TData>) => {
  return useQuery<CustomTokenUser, ErrorResponse, TData>({
    queryKey: ['custom-token-user', tokenId, userId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.customTokenUser(tokenId, userId))
      const data = response.data
      return validateSchema(customTokenUser, data, 'useCustomTokenUser')
    },
    ...options,
  })
}
