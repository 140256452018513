import {
  AddOutlined as AddOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import AddEmploymentTypeDialog from './AddEmploymentTypeDialog'
import ConfirmDialog from 'components/ConfirmDialog'
import { EmploymentType } from 'types/organization'
import ReassignEmploymentTypeDialog from './ReassignEmploymentTypeDialog'
import { convertToPercentage } from 'utils/numbers'
import { useEmploymentTypes } from 'services/api/organization'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import KeeprTable from 'components/KeeprTable'

const EmploymentTypes = () => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [isAddEmploymentTypeDialogOpen, setIsAddEmploymentTypeDialogOpen] = useState<boolean>(false)
  const [isRemoveEmploymentTypeDialogOpen, setIsRemoveEmploymentTypeDialogOpen] =
    useState<boolean>(false)
  const [isReassignEmploymentTypeDialogOpen, setIsReassignEmploymentTypeDialogOpen] =
    useState<boolean>(false)

  const [selectedEmploymentType, setSelectedEmploymentType] = useState<number | null>(null)

  const { data: employmentTypes = [] } = useEmploymentTypes({
    options: {
      select: (data) => data.filter((employmentType) => !employmentType.is_default),
    },
  })

  const { mutate: addEmploymentType, isPending: isAddEmploymentTypePending } = useMutation<
    EmploymentType,
    ErrorResponse,
    Partial<EmploymentType>
  >({
    mutationFn: (employmentType) => {
      if (selectedEmploymentType) {
        return baseApiClient
          .patch(API.org.employmentType(selectedEmploymentType), employmentType)
          .then((response) => response.data)
      }

      return baseApiClient
        .post(API.org.employmentTypeList(), employmentType)
        .then((response) => response.data)
    },
    onSuccess: (addedEmploymentType) => {
      queryClient.setQueryData<EmploymentType[] | undefined>(
        ['employment-types'],
        (employmentTypes) => {
          if (!employmentTypes) return undefined
          if (selectedEmploymentType) {
            return employmentTypes.map((employmentType) => {
              if (employmentType.id === selectedEmploymentType) {
                return addedEmploymentType
              }
              return employmentType
            })
          }
          return [...employmentTypes, addedEmploymentType]
        },
      )

      if (selectedEmploymentType) {
        enqueueSnackbar('Employment type updated successfully.', { variant: 'success' })
      } else {
        enqueueSnackbar('Employment type added successfully.', { variant: 'success' })
      }

      closeEditEmploymentTypeDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { mutate: removeEmploymentType, isPending: isRemoveEmploymentTypePending } = useMutation<
    number,
    ErrorResponse,
    number
  >({
    mutationFn: (employmentTypeForDeleteId) =>
      baseApiClient
        .delete(API.org.employmentType(employmentTypeForDeleteId))
        .then((response) => response.data),
    onSuccess: (_, employmentTypeForDeleteId) => {
      queryClient.setQueryData<EmploymentType[] | undefined>(
        ['employment-types'],
        (tiers) => tiers?.filter((tier) => tier.id !== employmentTypeForDeleteId),
      )

      enqueueSnackbar(
        'Employment type removed successfully.\nPlease review all automatic distributions.',
        { variant: 'success' },
      )

      closeRemoveEmploymentTypeDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const onAddEmploymentType = (employmentType: Partial<EmploymentType>) => {
    addEmploymentType(employmentType)
  }

  const openEditEmploymentTypeDialog = (employmentTypeId: number) => {
    setSelectedEmploymentType(employmentTypeId)
    setIsAddEmploymentTypeDialogOpen(true)
  }

  const closeEditEmploymentTypeDialog = () => {
    setIsAddEmploymentTypeDialogOpen(false)
    setSelectedEmploymentType(null)
  }

  const openRemoveEmploymentTypeDialog = (employmentTypeId: number) => {
    setSelectedEmploymentType(employmentTypeId)
    setIsRemoveEmploymentTypeDialogOpen(true)
  }

  const closeRemoveEmploymentTypeDialog = () => {
    setIsRemoveEmploymentTypeDialogOpen(false)
    setSelectedEmploymentType(null)
  }

  const employmentType = useMemo(() => {
    return (
      employmentTypes.find((employmentType) => employmentType.id === selectedEmploymentType) || null
    )
  }, [employmentTypes, selectedEmploymentType])

  const onRemoveEmploymentType = () => {
    if (!employmentType) {
      return
    }

    if (employmentType.jobs_count) {
      setIsReassignEmploymentTypeDialogOpen(true)
      return
    }

    removeEmploymentType(employmentType.id)
  }

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: 'Name',
          Cell: ({ cell }) => {
            const name = cell.getValue<string>()
            return (
              <Box overflow='hidden'>
                <Typography variant='body2' noWrap={true}>
                  {name}
                </Typography>
              </Box>
            )
          },
        },
        {
          accessorKey: 'multiplier',
          header: 'Multiplier',
          Header: (
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography variant='subtitle2'>Multiplier</Typography>
              <Tooltip
                title="An employment type's impact on automatic token distributions"
                placement='top-start'
                componentsProps={{ tooltip: { sx: { width: '164px' } } }}
              >
                <InfoOutlinedIcon
                  sx={{
                    fontSize: 16,
                    color: 'text.secondary',
                  }}
                />
              </Tooltip>
            </Stack>
          ),
          size: 75,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const multiplier = cell.getValue<string>()
            return <Typography variant='body2'>{convertToPercentage(multiplier)}%</Typography>
          },
        },
        {
          id: 'actions',
          header: 'Actions',
          size: 50,
          muiTableHeadCellProps: {
            align: 'center',
          },
          Cell: ({ row }) => {
            return (
              <Stack direction='row' justifyContent='right'>
                <IconButton
                  aria-label='edit'
                  size='small'
                  sx={{ padding: 0.5 }}
                  onClick={() => openEditEmploymentTypeDialog(row.original.id)}
                >
                  <EditIcon fontSize='inherit' />
                </IconButton>
                <IconButton
                  aria-label='delete'
                  size='small'
                  sx={{ padding: 0.5 }}
                  onClick={() => openRemoveEmploymentTypeDialog(row.original.id)}
                >
                  <DeleteOutlineIcon fontSize='inherit' />
                </IconButton>
              </Stack>
            )
          },
        },
      ] as MRT_ColumnDef<EmploymentType>[],
    [],
  )

  return (
    <>
      <Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Organization members are assigned an employment type. This influences the amount a member
          receives from a distribution.
        </Typography>

        <Box mt={3} mb={2}>
          <KeeprTable
            columns={columns}
            data={employmentTypes}
            muiTablePaperProps={{
              elevation: 0,
            }}
            muiTableBodyProps={{
              title: 'Employment types table',
            }}
          />
        </Box>

        <Button
          variant='outlined'
          startIcon={<AddOutlinedIcon />}
          onClick={() => setIsAddEmploymentTypeDialogOpen(true)}
        >
          Add employment type
        </Button>
      </Box>

      {isAddEmploymentTypeDialogOpen ? (
        <AddEmploymentTypeDialog
          open={isAddEmploymentTypeDialogOpen}
          handleClose={closeEditEmploymentTypeDialog}
          handleAddEmploymentType={onAddEmploymentType}
          isLoading={isAddEmploymentTypePending}
          employmentType={employmentType}
        />
      ) : null}

      {isRemoveEmploymentTypeDialogOpen ? (
        <ConfirmDialog
          isOpen={isRemoveEmploymentTypeDialogOpen}
          handleClose={closeRemoveEmploymentTypeDialog}
          title={`Remove ${employmentType?.name} employment type`}
          content={
            employmentType?.jobs_count
              ? `The ${employmentType.name} employment type will be removed, from all ongoing distributions their amounts will be updated. You will need to reassign users to an alternative employment type before this can be removed.`
              : 'Are you sure you wish to remove this employment type?'
          }
          onCancel={closeRemoveEmploymentTypeDialog}
          cancelText='Cancel'
          onConfirm={onRemoveEmploymentType}
          confirmText={
            employmentType?.jobs_count
              ? `Reassign users (${employmentType.jobs_count})`
              : 'Remove employment type'
          }
          confirmType={employmentType?.jobs_count ? `primary` : 'error'}
          isLoading={isRemoveEmploymentTypePending}
        />
      ) : null}

      {isReassignEmploymentTypeDialogOpen ? (
        <ReassignEmploymentTypeDialog
          open={isReassignEmploymentTypeDialogOpen}
          handleClose={() => setIsReassignEmploymentTypeDialogOpen(false)}
          employmentType={employmentType}
        />
      ) : null}
    </>
  )
}

export default EmploymentTypes
