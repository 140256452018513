import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'

import Avatar from '../Avatar'
import Edit from './Edit'
import { Photo as PhotoIcon } from '@mui/icons-material'
import Upload from '../Upload'
import { image } from 'constants/allowedFiles'

interface AvatarUploadProps {
  uploadTitle?: string
  isUserAvatar?: boolean
  isOpen: boolean
  handleClose: () => void
  handleSaveAvatar: (image: string) => void
}

const AvatarUpload = ({
  isOpen,
  handleClose,
  handleSaveAvatar,
  uploadTitle,
  isUserAvatar,
}: AvatarUploadProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const avatarEditor = useRef<any>(null)
  const [uploadedAvatar, setUploadedAvatar] = useState<File | null>(null)
  const [previewImage, setPreviewImage] = useState<string>('')

  const onAvatarUpload = (file: File) => {
    setUploadedAvatar(file)

    const reader = new FileReader()
    reader.onload = () => {
      setPreviewImage(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  const onSaveAvatar = async () => {
    if (!avatarEditor.current) {
      return
    }

    const image = await avatarEditor.current.getImage().toDataURL()

    handleSaveAvatar(image)
  }

  const onAvatarEdited = async () => {
    const newImage = await avatarEditor.current.getImageScaledToCanvas().toDataURL()
    setPreviewImage(newImage)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={!uploadedAvatar ? uploadTitle : 'Edit your image'}
      open={isOpen}
    >
      <DialogTitle>{!uploadedAvatar ? uploadTitle : 'Edit your image'}</DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        {!uploadedAvatar ? (
          <Upload allowedFiles={image} fileIcon={PhotoIcon} onFileChange={onAvatarUpload}>
            {isUserAvatar ? (
              <Box>
                <Typography variant='body2' color='text.secondary' mt={1}>
                  You can upload PNG or JPG (max. 3MB)
                </Typography>

                <Typography variant='body2' color='text.secondary' mt={1}>
                  Minimum size of 256px * 256px
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant='body2' color='text.secondary' mt={1}>
                  We recommend an SVG file. You can also upload PNG or JPG (max. 3MB)
                </Typography>

                <Typography variant='body2' color='text.secondary' mt={1}>
                  Minimum size of 800px * 800px
                </Typography>
              </Box>
            )}
          </Upload>
        ) : (
          <>
            <Edit
              ref={avatarEditor}
              avatar={uploadedAvatar}
              onAvatarEdited={onAvatarEdited}
              uploadTitle={uploadTitle}
            />
            <Box mt={1.5}>
              <Typography
                variant='overline'
                textTransform='uppercase'
                letterSpacing='1px'
                color='text.secondary'
              >
                Preview
              </Typography>
              <Avatar
                name=''
                src={previewImage}
                size={48}
                sx={{ mt: 0.5, borderRadius: uploadTitle ? 50 : 0 }}
                data-testid='uploaded-image-preview'
              />
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant='outlined' onClick={handleClose} data-testid='upload-cancel'>
          Cancel
        </Button>
        <Button variant='contained' onClick={onSaveAvatar} data-testid='upload-save'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AvatarUpload
