interface DownloadFile {
  file: any
  name: string
  type?: string
  extension?: string
}

export const downloadFile = ({
  file,
  name,
  type = 'text/csv',
  extension = 'csv',
}: DownloadFile) => {
  const blob = new Blob([file], { type: type })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = `${name}.${extension}`
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(url)
  document.body.removeChild(link)
}
