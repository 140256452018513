import { PaginatedResponse, currencyOptionsSchema } from 'types/common'
import { userSchema } from 'types/user'
import { z } from 'zod'
import { tokenDistributionScheduleSchema } from './basicToken'
import { payoutStatusSchema } from './profitShare'

export const customTokenUsersSearch = userSchema.pick({
  uuid: true,
  email: true,
  first_name: true,
  last_name: true,
  full_name: true,
})

export type CustomTokenUsersSearch = z.infer<typeof customTokenUsersSearch>
export type PaginatedCustomTokenUsersSearch = PaginatedResponse<CustomTokenUsersSearch>

export const customTokenUser = z.object({
  id: z.number(),
  uuid: z.string(),
  email: z.string().email(),
  full_name: z.string(),
  avatar: z.string().nullable(),
  dist_amount: z.number(),
  dist_schedule: tokenDistributionScheduleSchema,
  balance: z.number(),
  employment_type: z.string(),
  company_tier: z.string(),
})

export const customTokenType = z.object({
  id: z.number(),
  order: z.number(),
  name: z.string(),
  description: z.string(),
  props: z.array(z.string()),
})

export const customTokenPayoutDetailSchema = z.object({
  id: z.number(),
  payout_balance: z.number(),
  payout_value: z.string(),
  user: z.string(),
})

export const customTokenPayoutSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  status: payoutStatusSchema,
  is_preview: z.boolean(),
  start_dt: z.string().nullable(),
  end_dt: z.string().nullable(),
  payout_dt: z.string(),
  payout_value: z.string(),
  created_at: z.string(),
  token_amount: z.number(),
  token_value: z.string(),
  users_count: z.number(),
  created_by_email: z.string(),
  created_by_full_name: z.string(),
  currency: currencyOptionsSchema,
  token_logo: z.string().nullable(),
  payout_fee: z.string(),
  payout_detail: customTokenPayoutDetailSchema.nullable(),
})

export const customTokenPayoutUserSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  email: z.string().email(),
  avatar_thumbnail: z.string().nullable(),
  payout_balance: z.number(),
  payout_value: z.string(),
  net_payout_value: z.string(),
})

export const customTokenTypeList = z.array(customTokenType)

export type CustomTokenType = z.infer<typeof customTokenType>
export type CustomTokenTypeList = z.infer<typeof customTokenTypeList>

export type CustomTokenUser = z.infer<typeof customTokenUser>
export type PaginatedCustomTokenUserList = PaginatedResponse<CustomTokenUser>

export type CustomTokenPayout = z.infer<typeof customTokenPayoutSchema>

export type PaginatedCustomTokenPayoutList = PaginatedResponse<CustomTokenPayout>

export type CustomTokenPayoutUser = z.infer<typeof customTokenPayoutUserSchema>

export type PaginatedCustomTokenPayoutUserList = PaginatedResponse<CustomTokenPayoutUser>
