import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useProfileFullName, useProfile } from 'hooks/useUserProfileStore'

import { API_ENDPOINTS as API } from 'services/endpoints'
import Avatar from 'components/Avatar'
import AvatarUpload from 'components/avatar-upload/AvatarUpload'
import { LoadingButton } from '@mui/lab'
import { UserProfile } from 'types/user'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

interface ProfileProps {
  handleClose: () => void
}

const userSettingsFormSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  avatar: z.string().nullable().optional(),
  company_tier: z.string().optional(),
  employment_type: z.string().optional(),
})

type UserSettingsFormValues = z.infer<typeof userSettingsFormSchema>
type PartialUserProfile = Partial<UserProfile>

const USER_PROFILE_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  avatar: '',
  company_tier: '',
  employment_type: '',
}

const convertUserToFormValues = (user: PartialUserProfile): UserSettingsFormValues => {
  return {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    avatar: user.avatar,
    company_tier: user.user_job?.company_tier,
    employment_type: user.user_job?.employment_type,
  }
}

const Profile = ({ handleClose }: ProfileProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()
  const profileFullName = useProfileFullName()
  const userProfile = useProfile()

  const [isAvatarUploadDialogOpen, setIsAvatarUploadDialogOpen] = useState<boolean>(false)

  const { mutate: userProfileUpdate, isPending: isUserProfileUpdatePending } = useMutation<
    UserSettingsFormValues,
    ErrorResponse & { avatar: string[] },
    Partial<UserSettingsFormValues>
  >({
    mutationFn: async (data) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { avatar, ...otherFields } = data

      const formData = new FormData()

      Object.keys(otherFields).forEach((field) => {
        formData.append(field, data[field as keyof UserSettingsFormValues]?.toString() || '')
      })

      let blobImage = null
      if (avatar) {
        blobImage = await fetch(avatar).then((r) => r.blob())
        formData.append('avatar', blobImage, 'avatar.png')
      }

      if (avatar === '') {
        formData.append('avatar', '')
      }
      return baseApiClient
        .patch(API.user.profile(), formData, config)
        .then((response) => response.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      queryClient.invalidateQueries({ queryKey: ['users'] })
      queryClient.invalidateQueries({ queryKey: ['profit-share-holders'] })
      enqueueSnackbar('Profile updated.', { variant: 'success' })
      handleClose()
    },
    onError: (error) => {
      if (error?.avatar) {
        enqueueSnackbar(error.avatar[0], { variant: 'error' })
        return
      }
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, dirtyFields, isDirty },
  } = useForm<UserSettingsFormValues>({
    resolver: zodResolver(userSettingsFormSchema),
    defaultValues: userProfile ? convertUserToFormValues(userProfile) : USER_PROFILE_DEFAULT_VALUES,
    mode: 'onBlur',
  })

  const watchAvatar = watch('avatar')

  const onAvatarChange = (avatar: string) => {
    setValue('avatar', avatar, { shouldDirty: true })
    setIsAvatarUploadDialogOpen(false)
  }

  const onSubmit = (data: UserSettingsFormValues) => {
    const changedValues = Object.keys(data).reduce(
      (acc: Partial<UserSettingsFormValues>, field) => {
        const fieldKey = field as keyof UserSettingsFormValues

        if (dirtyFields[fieldKey]) {
          return { [fieldKey]: data[fieldKey], ...acc }
        }
        return acc
      },
      {},
    )

    userProfileUpdate(changedValues)
  }

  return (
    <Stack gap={3} width='auto'>
      <Alert severity='info'>
        Your admin manages some of your account details. Please contact them to request changes
      </Alert>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' flexDirection='row' gap={3} alignItems='center'>
            <Avatar
              name={profileFullName}
              src={watchAvatar}
              size={80}
              sx={{
                fontSize: 40,
              }}
            />

            <Button
              variant='outlined'
              sx={{
                width: 70,
                height: 30,
              }}
              onClick={() => setIsAvatarUploadDialogOpen(true)}
              data-testid='upload-avatar'
            >
              Change
            </Button>
            {watchAvatar !== null && watchAvatar !== 'removed' ? (
              <Button
                variant='text'
                sx={{
                  width: 70,
                  height: 30,
                }}
                onClick={() => setValue('avatar', '', { shouldDirty: true })}
              >
                Remove
              </Button>
            ) : null}
          </Box>
          <Typography variant='caption'>
            We recommend minimum dimensions of 256px * 256px.
          </Typography>

          <Stack direction='row' gap={2}>
            <Controller
              name='first_name'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='First name'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  required
                  data-testid='first-name'
                />
              )}
            />
            <Controller
              name='last_name'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Last name'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  required
                  data-testid='last-name'
                />
              )}
            />
          </Stack>
          <Stack direction='row' gap={2}>
            <Controller
              name='company_tier'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Tier level'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  disabled
                  data-testid='tier-level'
                />
              )}
            />
            <Controller
              name='employment_type'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Employment status'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  disabled
                  data-testid='employment-status'
                />
              )}
            />
          </Stack>
          <Stack direction='row' gap={2} justifyContent='flex-end' py={1}>
            <Button onClick={handleClose} data-testid='cancel-changes'>
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              loading={isUserProfileUpdatePending}
              disabled={!isDirty || !isValid}
              data-testid='profile-save-changes'
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Box>
        {isAvatarUploadDialogOpen ? (
          <AvatarUpload
            uploadTitle='Upload your profile picture'
            isOpen={isAvatarUploadDialogOpen}
            handleClose={() => setIsAvatarUploadDialogOpen(false)}
            handleSaveAvatar={onAvatarChange}
            isUserAvatar={true}
          />
        ) : null}
      </form>
    </Stack>
  )
}
export default Profile
