import { PaginatedResponse, currencyOptionsSchema } from '../common'
import { tokenDistributionScheduleSchema } from './basicToken'
import { tokenTypeOptionsSchema } from './basicToken'
import { stockOptionHolderSchema } from './stockOption'
import { z } from 'zod'
import { distributionTransferTypeSchema, processTypeSchema } from './tokenDistribution'
import { transactionNoteSchema } from 'types/note'

export const tokenTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
})

export const createAutomaticDistributionTokenTierSchema = z.object({
  value: z.number(),
  company_tier: z.number(),
})

export type CreateAutomaticDistributionTokenTier = z.infer<
  typeof createAutomaticDistributionTokenTierSchema
>

export const chartTokenSchema = z.object({
  uuid: z.string(),
  abbreviation: z.string(),
  logo_thumbnail: z.string().nullable(),
  name: z.string(),
  currency: currencyOptionsSchema,
  token_type: tokenTypeOptionsSchema,
  amount_allocated: z.number().nullable(),
  description: z.string().nullable(),
})

export const tokenBalanceSchema = z.object({
  amount: z.number(),
  token: chartTokenSchema,
  stock_option: stockOptionHolderSchema.nullable(),
  workspace: z.string(),
})

export const userTokenBalanceSchema = tokenBalanceSchema.omit({ workspace: true })

export const tokenTransactionSchema = z.object({
  amount: z.number(),
  txn_date: z.string(),
  created_at: z.string(),
  payer: z.string(),
  token: chartTokenSchema,
  transaction_type: distributionTransferTypeSchema,
  process_type: processTypeSchema,
  exercise: z.boolean().optional(),
  notes: z.array(transactionNoteSchema),
})

export const profitShareTransactionSchema = z.object({
  id: z.number(),
  amount: z.number(),
  txn_date: z.string(),
  created_at: z.string(),
  transaction_type: distributionTransferTypeSchema,
  organization: z.string(),
  notes: z.array(transactionNoteSchema),
})

export const currentUserPayoutSchema = z.object({
  id: z.number(),
  name: z.string(),
  organization: z.string(),
  payout_dt: z.string(),
  start_dt: z.string(),
  end_dt: z.string(),
  token_value: z.number(),
  payout_balance: z.number(),
  payout_value: z.string(),
  currency: currencyOptionsSchema,
})

export const PAYOUT_STATUS = {
  Pending: 'pending',
  Completed: 'completed',
  Canceled: 'canceled',
} as const

export const payoutStatusSchema = z.enum([
  PAYOUT_STATUS.Pending,
  PAYOUT_STATUS.Completed,
  PAYOUT_STATUS.Canceled,
])

export type PayoutStatus = z.infer<typeof payoutStatusSchema>

export const profitSharePayoutSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  status: payoutStatusSchema,
  is_preview: z.boolean(),
  start_dt: z.string(),
  end_dt: z.string(),
  payout_dt: z.string(),
  payout_value: z.string(),
  created_at: z.string(),
  token_amount: z.number(),
  token_value: z.string(),
  users_count: z.number(),
  created_by_email: z.string().email(),
  created_by_full_name: z.string(),
  token_logo: z.string().nullable(),
  currency: currencyOptionsSchema,
  payout_fee: z.string(),
  payout_fee_pct: z.number(),
  tiers: z
    .object({
      id: z.number(),
      name: z.string(),
      token_balance: z.number(),
      payout_value: z.string(),
      users_count: z.number(),
    })
    .array(),
})

export const payoutTierUserSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  email: z.string().email(),
  avatar_thumbnail: z.string().nullable(),
  payout_balance: z.number(),
  payout_value: z.string(),
  net_payout_value: z.string(),
})

export const userPayoutSchema = z.object({
  id: z.number(),
  payout_id: z.number(),
  payout_title: z.string(),
  payout_balance: z.number(),
  payout_value: z.string(),
  payout_dt: z.string(),
  start_dt: z.string(),
  end_dt: z.string(),
  currency: currencyOptionsSchema,
  token_uuid: z.string(),
  token_name: z.string(),
  token_thumbnail: z.string(),
  token_abbreviation: z.string(),
  token_value: z.number(),
})

export type TokenType = z.infer<typeof tokenTypeSchema>

export type TokenDistributionSchedule = z.infer<typeof tokenDistributionScheduleSchema>

export type ChartToken = z.infer<typeof chartTokenSchema>

export type TokenBalance = z.infer<typeof tokenBalanceSchema>

export type UserTokenBalance = z.infer<typeof userTokenBalanceSchema>

export type TokenTransaction = z.infer<typeof tokenTransactionSchema>

export type PaginatedTokenTransactionList = PaginatedResponse<TokenTransaction>

export type ProfitShareTransaction = z.infer<typeof profitShareTransactionSchema>

export type PaginatedProfitShareTransactionList = PaginatedResponse<ProfitShareTransaction>

export type CurrentUserPayout = z.infer<typeof currentUserPayoutSchema>

export type PaginatedCurrentUserPayoutList = PaginatedResponse<CurrentUserPayout>

export type ProfitSharePayout = z.infer<typeof profitSharePayoutSchema>

export type PaginatedProfitSharePayout = PaginatedResponse<ProfitSharePayout>

export type PayoutTierUser = z.infer<typeof payoutTierUserSchema>

export type PaginatedPayoutTierUserList = PaginatedResponse<PayoutTierUser>

export type UserPayout = z.infer<typeof userPayoutSchema>

export type PaginatedUserPayoutList = PaginatedResponse<UserPayout>
