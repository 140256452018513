import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { EmploymentType, employmentTypeSchema } from 'types/organization'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { convertToDecimalPercentage, convertToPercentage, isOverLimit } from 'utils/numbers'
import { MAX_EMPLOYMENT_TYPE_NAME_LENGTH } from 'constants/common'

import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

export const employmentTypeFormSchema = employmentTypeSchema.pick({ name: true, multiplier: true })

export type EmploymentTypeForm = z.infer<typeof employmentTypeFormSchema>

const EMPLOYMENT_TYPE_FORM_DEFAULT_VALUES: EmploymentTypeForm = {
  name: '',
  multiplier: '',
}

interface AddEmploymentTypeDialog {
  open: boolean
  handleClose: () => void
  handleAddEmploymentType: (employmentType: Partial<EmploymentTypeForm>) => void
  isLoading: boolean
  employmentType: EmploymentType | null
}

const AddEmploymentTypeDialog = ({
  open,
  handleClose,
  handleAddEmploymentType,
  isLoading,
  employmentType,
}: AddEmploymentTypeDialog) => {
  const {
    handleSubmit,
    control,
    reset,

    formState: { dirtyFields, isValid },
  } = useForm<EmploymentTypeForm>({
    mode: 'onBlur',
    resolver: zodResolver(employmentTypeFormSchema),
    defaultValues: EMPLOYMENT_TYPE_FORM_DEFAULT_VALUES,
  })

  useEffect(() => {
    if (!employmentType) {
      return
    }

    reset({
      name: employmentType.name,
      multiplier: String(convertToPercentage(employmentType.multiplier)),
    })
  }, [reset, employmentType])

  const onSubmit = (values: EmploymentTypeForm) => {
    const changedValues = Object.keys(values).reduce((acc: Partial<EmploymentTypeForm>, field) => {
      const fieldKey = field as keyof EmploymentTypeForm
      if (dirtyFields[fieldKey]) {
        return { [fieldKey]: values[fieldKey], ...acc }
      }
      return { ...acc }
    }, {})

    if (changedValues.multiplier) {
      handleAddEmploymentType({
        ...changedValues,
        multiplier: String(convertToDecimalPercentage(changedValues.multiplier)),
      })
    } else {
      handleAddEmploymentType(changedValues)
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='Add employment type' open={open}>
      <DialogTitle>{employmentType ? 'Edit employment type' : 'Add employment type'}</DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <form id='add-employment-type-form' onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name='name'
            control={control}
            render={({ field, fieldState: { error } }) => {
              const charCount = field.value.length || 0
              const isOverNameLimit = isOverLimit(charCount, MAX_EMPLOYMENT_TYPE_NAME_LENGTH)
              return (
                <TextField
                  label='Employment type name'
                  {...field}
                  fullWidth
                  error={!!error || isOverNameLimit}
                  helperText={`${charCount}/${MAX_EMPLOYMENT_TYPE_NAME_LENGTH}` || error?.message}
                  inputProps={{ maxLength: MAX_EMPLOYMENT_TYPE_NAME_LENGTH }}
                  FormHelperTextProps={{
                    sx: { textAlign: 'right' },
                  }}
                  sx={{ mb: 2 }}
                />
              )
            }}
          />

          <Controller
            name='multiplier'
            control={control}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <NumericFormat
                customInput={TextField}
                label='Multiplier'
                value={value}
                onValueChange={(value: NumberFormatValues) => {
                  onChange(value.value)
                }}
                onBlur={onBlur}
                error={!!error}
                helperText={error?.message}
                fullWidth
                allowNegative={false}
                decimalScale={2}
                isAllowed={(value: NumberFormatValues) => {
                  const floatValue = value.floatValue || 0
                  return floatValue <= 100
                }}
                suffix='%'
              />
            )}
          />
        </form>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          type='submit'
          form='add-employment-type-form'
          disabled={!isValid}
          loading={isLoading}
        >
          {employmentType ? 'Update' : 'Add employment type'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddEmploymentTypeDialog
