import { currencyOptionsSchema } from 'types/common'
import { uuidRegex } from 'utils/validations'
import { z } from 'zod'

export const TOKEN_TYPE_OPTIONS = {
  StockOption: 'Stock Option',
  ProfitShare: 'Profit Share',
  Custom: 'Custom',
} as const

export const tokenTypeOptionsSchema = z.enum([
  TOKEN_TYPE_OPTIONS.StockOption,
  TOKEN_TYPE_OPTIONS.ProfitShare,
  TOKEN_TYPE_OPTIONS.Custom,
])

export const CUSTOM_TOKEN_TYPE_OPTIONS = {
  SelectiveCashShare: 'Selective cash share',
  IndividualRedemption: 'Individual redemption',
  FixedPool: 'Fixed pool',
} as const

export const customTokenTypeOptionsSchema = z.enum([
  CUSTOM_TOKEN_TYPE_OPTIONS.SelectiveCashShare,
  CUSTOM_TOKEN_TYPE_OPTIONS.IndividualRedemption,
  CUSTOM_TOKEN_TYPE_OPTIONS.FixedPool,
])

export const getTokenTypeURL = (tokenType: TokenTypeOptions) => {
  switch (tokenType) {
    case TOKEN_TYPE_OPTIONS.StockOption:
      return 'stock-option'
    case TOKEN_TYPE_OPTIONS.ProfitShare:
      return 'profit-share'
    case TOKEN_TYPE_OPTIONS.Custom:
      return 'custom-asset'
  }
}

export const TOKEN_DISTRIBUTION_SCHEDULE = {
  Monthly: 'monthly',
  Weekly: 'weekly',
  Quarterly: 'quarterly',
  No_Schedule: 'no_schedule',
} as const

export const tokenDistributionScheduleSchema = z.enum([
  TOKEN_DISTRIBUTION_SCHEDULE.Monthly,
  TOKEN_DISTRIBUTION_SCHEDULE.Weekly,
  TOKEN_DISTRIBUTION_SCHEDULE.Quarterly,
  TOKEN_DISTRIBUTION_SCHEDULE.No_Schedule,
])

export const companyTierSchema = z.object({
  id: z.number(),
  name: z.string(),
})

export const tokenTierSchema = z.object({
  value: z.number(),
  company_tier: companyTierSchema,
  balance: z.number(),
})

export const tokenSchema = z.object({
  abbreviation: z.string(),
  uuid: z.string().regex(uuidRegex),
  name: z.string(),
  description: z.string(),
  token_type: tokenTypeOptionsSchema,
  logo: z.string().optional().nullable(),
  logo_thumbnail: z.string().optional().nullable(),
  created_at: z.string(),
  token_tiers: z.array(tokenTierSchema),
  holders_count: z.number(),
  has_distributions: z.boolean(),

  //Profit share properties
  distribution_schedule: tokenDistributionScheduleSchema,
  distribution_date: z.string().optional().nullable(),
  amount_allocated: z.number(),
  currency: currencyOptionsSchema,
  all_distribution_dates: z.object({
    [tokenDistributionScheduleSchema.Enum.monthly]: z.string(),
    [tokenDistributionScheduleSchema.Enum.weekly]: z.string(),
    [tokenDistributionScheduleSchema.Enum.quarterly]: z.string(),
  }),

  // stock options properties
  token_pool_amount: z.number(),
  iso_exercised_total: z.number(),
  iso_amount_used: z.number(),
  iso_options_remaining: z.number(),
  iso_vested_total: z.number(),

  // custom token properties
  custom_token_users_count: z.number(),
  custom_token_type: customTokenTypeOptionsSchema.nullable(),
  custom_pool_amount: z.number().nullable(),
  custom_unit_value: z.string().nullable(),
})

export type Token = z.infer<typeof tokenSchema>

export type TokenTypeOptions = z.infer<typeof tokenTypeOptionsSchema>

export type CustomTokenTypeOptions = z.infer<typeof customTokenTypeOptionsSchema>

export type CompanyTier = z.infer<typeof companyTierSchema>

export type TokenTier = z.infer<typeof tokenTierSchema>
