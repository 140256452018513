import { useEffect, useState } from 'react'
import { ArrowBack as ArrowBackIcon, ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// import tinycolor2 from 'tinycolor2'

import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import Avatar from 'components/Avatar'
import AvatarUpload from 'components/avatar-upload/AvatarUpload'
import ColorPicker from 'components/color-picker/ColorPicker'
import EmploymentTypes from './EmploymentTypes'
import { LoadingButton } from '@mui/lab'
import { Organization } from 'types/organization'
import SetTiers from 'features/home/onboarding/set-tiers/SetTiers'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { hexColorRegexExpression, validateHexColor } from 'utils/validations'
import useCountries from 'hooks/useCountries'
import { useOrganization } from 'services/api/organization'
import {
  useOrganizationActions,
  // useOrganizationIsDarkTheme
} from 'hooks/useOrganizationStore'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { isOverLimit } from 'utils/numbers'
import { MAX_ORG_NAME_LENGTH } from 'constants/common'

const organizationSettingsFormSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name is required.' })
    .max(MAX_ORG_NAME_LENGTH, {
      message: `Payout name must be ${MAX_ORG_NAME_LENGTH} characters or less.`,
    }),
  subdomain: z.string(),
  country: z.string().min(1, { message: 'Country is required.' }),
  logo: z.string().nullable(),
  org_color: z
    .string()
    .regex(hexColorRegexExpression, { message: 'Organization color is invalid.' }),
})

type OrganizationSettingsForm = z.infer<typeof organizationSettingsFormSchema>

const INITIAL_FORM_DATA: OrganizationSettingsForm = {
  name: '',
  subdomain: '',
  country: '',
  logo: null,
  org_color: '',
}

const General = () => {
  const queryClient = useQueryClient()
  const { setOrganization, setOrgColor } = useOrganizationActions()
  const countryList = useCountries()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [isAvatarUploadDialogOpen, setIsAvatarUploadDialogOpen] = useState<boolean>(false)
  const [showOrganizationTiers, setShowOrganizationTiers] = useState<boolean>(false)
  const [showEmploymentTypes, setShowEmploymentTypes] = useState<boolean>(false)

  const { data: organization = null } = useOrganization({})
  // const isDarkTheme = useOrganizationIsDarkTheme()

  const orgAddressId = organization?.org_address?.id || null

  const { mutateAsync: updateOrganization, isPending: isUpdateOrganizationPending } = useMutation<
    Organization,
    ErrorResponse,
    Partial<Organization>
  >({
    mutationFn: async (organization) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { logo, ...otherFields } = organization

      const formData = new FormData()

      Object.keys(otherFields).forEach((field) => {
        formData.append(
          field,
          organization[field as keyof Omit<OrganizationSettingsForm, 'country'>]?.toString() || '',
        )
      })

      let blobImage = null
      if (logo) {
        blobImage = await fetch(logo).then((res) => res.blob())
        formData.append('logo', blobImage, 'organization-logo.png')
      }

      return baseApiClient
        .patch(API.org.details(), formData, config)
        .then((response) => response.data)
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const { mutateAsync: updateCountry, isPending: isUpdateCountryPending } = useMutation<
    Organization,
    ErrorResponse,
    Pick<OrganizationSettingsForm, 'country'>
  >({
    mutationFn: async (org_country) => {
      if (!orgAddressId) {
        return
      }

      return baseApiClient
        .patch(API.org.address(orgAddressId), org_country)
        .then((response) => response.data)
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { dirtyFields, isDirty, isValid, errors },
  } = useForm<OrganizationSettingsForm>({
    mode: 'onBlur',
    resolver: zodResolver(organizationSettingsFormSchema),
    defaultValues: INITIAL_FORM_DATA,
  })

  const [watchLogo, watchName, watchOrgColor] = useWatch<
    OrganizationSettingsForm,
    ['logo', 'name', 'org_color']
  >({
    name: ['logo', 'name', 'org_color'],
    control: control,
  })

  useEffect(() => {
    if (!organization) {
      return
    }

    reset({
      name: organization.name,
      subdomain: organization.subdomain,
      country: organization.org_address?.country || 'US',
      logo: organization.logo,
      org_color: organization.org_color,
    })
  }, [reset, organization])

  const onSubmit = async (values: OrganizationSettingsForm) => {
    const changedValues = Object.keys(values).reduce(
      (acc: Partial<OrganizationSettingsForm>, field) => {
        const fieldKey = field as keyof OrganizationSettingsForm
        if (dirtyFields[fieldKey]) {
          return { [fieldKey]: values[fieldKey], ...acc }
        }
        return { ...acc }
      },
      {},
    )

    const { country, ...otherFields } = changedValues

    try {
      if (country) {
        await updateCountry({ country })
      }

      if (Object.keys(otherFields).length > 0) {
        const response = await updateOrganization(otherFields)

        const {
          /* eslint-disable */
          /* eslint-disable no-unused-vars */
          logo,
          status_count,
          org_address,
          free_use,
          free_trial_days_left,
          trial_end_dt,
          has_subscribed,
          /* eslint-enable */
          ...rest
        } = response

        setOrganization({ ...rest })
      }

      queryClient.invalidateQueries({ queryKey: ['organization-details'] })
      queryClient.invalidateQueries({ queryKey: ['workspaces'] })

      enqueueSnackbar('Organization updated successfully', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    }
  }

  const onAvatarSave = (avatar: string) => {
    setValue('logo', avatar, { shouldDirty: true })
    setIsAvatarUploadDialogOpen(false)
  }

  //change org color on-the-fly for UI preview
  useEffect(() => {
    if (validateHexColor(watchOrgColor)) {
      setOrgColor(watchOrgColor)
    }
  }, [watchOrgColor, setOrgColor])

  // Dark mode related, not used for now
  // const showThemeNotification =
  //   (tinycolor2(watchOrgColor).isDark() && isDarkTheme) ||
  //   (!tinycolor2(watchOrgColor).isDark() && !isDarkTheme)

  return (
    <>
      {!showOrganizationTiers && !showEmploymentTypes ? (
        <form
          id='organization-settings-form'
          data-testid='organization-settings-form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Stack gap={2}>
            <Typography variant='h6'>Organization settings</Typography>

            <Controller
              name='name'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value.length || 0
                const isOverNameLimit = isOverLimit(charCount, MAX_ORG_NAME_LENGTH)

                return (
                  <TextField
                    label='Organization name'
                    data-testid='organization-name-field'
                    variant='outlined'
                    required
                    fullWidth
                    {...field}
                    error={!!error || isOverNameLimit}
                    helperText={`${charCount}/${MAX_ORG_NAME_LENGTH}` || error?.message}
                    inputProps={{ maxLength: MAX_ORG_NAME_LENGTH }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                  />
                )
              }}
            />

            <Stack direction='row' alignItems='center' gap={3}>
              <Controller
                name='subdomain'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label='Domain'
                    variant='outlined'
                    required
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    disabled={true}
                  />
                )}
              />

              <Typography width='100%' pb={errors.subdomain ? '23px' : 0}>
                .keepr.io
              </Typography>
            </Stack>

            <Controller
              name='country'
              control={control}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Autocomplete
                  id='country'
                  options={countryList.sort((a, b) => a.group.localeCompare(b.group))}
                  groupBy={(option) => option.group}
                  renderGroup={({ key, group, children }) => {
                    if (group === 'favorite') {
                      return children
                    }
                    return (
                      <div key={key}>
                        <Divider sx={{ border: '1px solid #D8D8D8' }} />
                        {children}
                      </div>
                    )
                  }}
                  onChange={(_event, item) => {
                    onChange(item?.value || '')
                  }}
                  onBlur={onBlur}
                  isOptionEqualToValue={(option, newValue) => option.value === newValue.value}
                  value={countryList.find((country) => country.value === value) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label='Country'
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      data-testid='country-name'
                    />
                  )}
                />
              )}
            />

            <Typography variant='h6' mt={2}>
              Customize branding
            </Typography>

            <Box>
              <Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
                <Avatar name={watchName} src={watchLogo} size={48} sx={{ fontSize: 20 }} />

                <Button
                  variant='outlined'
                  onClick={() => setIsAvatarUploadDialogOpen(true)}
                  data-testid='upload-change-avatar'
                >
                  {watchLogo ? 'Change' : 'Upload'}
                </Button>
              </Stack>

              <Typography
                color='text.secondary'
                ml='14px'
                mt={0.5}
                sx={{ transform: 'scale(0.75)', transformOrigin: '0% 50%' }}
              >
                Upload organization logo
              </Typography>
            </Box>

            <Controller
              name='org_color'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Box>
                  <TextField
                    label='Organization color'
                    {...field}
                    fullWidth
                    error={!!error}
                    helperText={error?.message && 'Please enter a valid hex color.'}
                    inputProps={{
                      maxLength: 7,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ marginRight: '10px' }}>
                          <Box
                            width='30px'
                            height='30px'
                            borderRadius='50%'
                            bgcolor={field.value}
                          />
                        </InputAdornment>
                      ),
                    }}
                    data-testid='organization-color'
                  />

                  <Stack mt={2} gap={2}>
                    <ColorPicker
                      color={field.value}
                      handleColor={(color) => {
                        setValue('org_color', color, { shouldValidate: true, shouldDirty: true })
                      }}
                    />
                    {/* Dark mode related, not used for now */}
                    {/* {showThemeNotification ? (
                      <Alert severity='warning'>
                        We recommend you to use the {isDarkTheme ? 'Light' : 'Dark'} mode.
                      </Alert>
                    ) : null} */}
                  </Stack>
                </Box>
              )}
            />

            <Link
              component='button'
              variant='h6'
              color='text.primary'
              onClick={() => setShowOrganizationTiers(true)}
              underline='none'
              type='button'
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 2,
              }}
              data-testid='organization-tiers'
            >
              <span>Organization tiers</span>
              <ArrowRightAltIcon sx={{ fontSize: '24px' }} />
            </Link>

            <Link
              component='button'
              variant='h6'
              color='text.primary'
              onClick={() => setShowEmploymentTypes(true)}
              underline='none'
              type='button'
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 2,
              }}
              data-testid='employment-types'
            >
              <span>Employment types</span>
              <ArrowRightAltIcon sx={{ fontSize: '24px' }} />
            </Link>

            <LoadingButton
              variant='contained'
              data-testid='update-organization'
              type='submit'
              sx={{ ml: 'auto', mt: 1 }}
              disabled={!isDirty || !isValid}
              loading={isUpdateOrganizationPending || isUpdateCountryPending}
            >
              Update
            </LoadingButton>
          </Stack>
        </form>
      ) : null}

      {showOrganizationTiers ? (
        <Box data-testid='organization-tiers-settings'>
          <Link
            component='button'
            data-testid='back-button'
            variant='h6'
            color='text.primary'
            onClick={() => setShowOrganizationTiers(false)}
            underline='none'
            type='button'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 1,
            }}
          >
            <ArrowBackIcon sx={{ fontSize: '20px' }} />
            <span>Organization tiers</span>
          </Link>

          <SetTiers onBoarding={false} />
        </Box>
      ) : null}

      {showEmploymentTypes ? (
        <Box>
          <Link
            component='button'
            variant='h6'
            color='text.primary'
            onClick={() => setShowEmploymentTypes(false)}
            underline='none'
            type='button'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 1,
            }}
          >
            <ArrowBackIcon sx={{ fontSize: '20px' }} />
            <span>Employment types</span>
          </Link>

          <EmploymentTypes />
        </Box>
      ) : null}

      {isAvatarUploadDialogOpen ? (
        <AvatarUpload
          uploadTitle='Upload your organization logo'
          isOpen={isAvatarUploadDialogOpen}
          handleClose={() => setIsAvatarUploadDialogOpen(false)}
          handleSaveAvatar={onAvatarSave}
        />
      ) : null}
    </>
  )
}

export default General
