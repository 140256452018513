type UrlParam = string | number

export const API_ENDPOINTS = {
  org: {
    details: () => '/api/org/',
    onboard: () => '/api/org/onboard/',
    tier: (tierId: UrlParam) => `/api/org/tier/${tierId}/`,
    tierList: () => '/api/org/tier/',
    tierOrder: () => '/api/org/tier/order/',
    reassignTier: () => '/api/org/tier/change/',
    theme: (subdomain: UrlParam) => `/api/org/theme/${subdomain}/`,
    employmentTypeList: () => '/api/org/employment_type/',
    employmentType: (employmentTypeId: UrlParam) => `/api/org/employment_type/${employmentTypeId}/`,
    reassignEmploymentType: () => '/api/org/employment_type/change/',
    updateEmploymentTypeMultiplier: () => '/api/org/employment_type/update/',
    initialAddress: () => '/api/org/address/',
    address: (id: UrlParam) => `/api/org/address/${id}/`,
  },
  stripe: {
    subscribe: () => '/api/org/stripe-subscribe/',
    customerPortal: () => '/api/org/stripe-portal/',
  },
  user: {
    create: () => '/api/account/user/create/',
    invite: () => '/api/account/user/invite/',
    reinvite: () => '/api/account/user/re_invite/',
    import: () => '/api/account/user/import/',
    details: (id: UrlParam) => `/api/account/user/${id}/`,
    list: () => `/api/account/user/`,
    tokenBalance: () => '/api/account/user/balance/',
    transaction: () => '/api/account/user/transaction/',
    profitShareTransactions: (tokenId: UrlParam) =>
      `/api/account/user/txn/profit-share/${tokenId}/`,
    payoutList: (tokenId: UrlParam) => `/api/account/user/payout/${tokenId}/`,
    userTokenBalance: (id: UrlParam) => `/api/account/user/${id}/balance/`,
    userTokenTransaction: (id: UrlParam) => `/api/account/user/${id}/transaction/`,
    profile: () => '/api/account/profile/',
    job: (id: UrlParam) => `/api/account/user/job/${id}/`,
    initialAddress: (id: UrlParam) => `/api/account/user/${id}/address/`,
    address: (id: UrlParam, addressId: UrlParam) => `/api/account/user/${id}/address/${addressId}/`,
    totalPayouts: () => '/api/account/user/payout/',
  },
  token: {
    list: () => '/api/token/',
    details: (tokenId: UrlParam) => `/api/token/${tokenId}/`,
    type: () => '/api/token/type/',
    customType: () => `/api/token/custom-type/`,
    balance: (tokenId: UrlParam) => `/api/token/balance/${tokenId}/`,
    balanceExportList: (tokenId: UrlParam) => `/api/token/balance/export/${tokenId}/`,
    distributionInfo: (id: UrlParam) => `/api/token/dist/info/${id}/`,
    distribution: (id: UrlParam) => `/api/token/distribution/${id}/`,
    distributionDetailsDownload: (tokenId: UrlParam, distributionId: UrlParam) =>
      `/api/token/distribution/${tokenId}/${distributionId}/`,
    distributionReverse: (distributionId: UrlParam) =>
      `/api/token/distribution/reverse/${distributionId}/`,
    distributionTier: (id: UrlParam) => `/api/token/tier/${id}/`,
    stockOptionHolders: (tokenId: UrlParam) => `/api/token/stock-option/${tokenId}/`,
    stockOptionHolder: (tokenId: UrlParam, id: UrlParam) =>
      `/api/token/stock-option/${tokenId}/${id}/`,
    stockOptionImport: (tokenId: UrlParam) => `/api/token/stock-option/import/${tokenId}/`,
    stockOptionHoldersNotes: (holderIsoId: UrlParam) =>
      `/api/token/stock-option/note/${holderIsoId}/`,
    stockOptionHoldersNote: (holderIsoId: UrlParam, noteId: UrlParam) =>
      `/api/token/stock-option/note/${holderIsoId}/${noteId}/`,
    transfer: (tokenId: UrlParam) => `/api/token/transfer/${tokenId}/`,
    stockOptionComplete: () => '/api/token/stock-option/complete/',
    stockOptionExpire: () => '/api/token/stock-option/expire/',
    stockOptionDisqualify: () => '/api/token/stock-option/disqualify/',
    exercise: {
      create: () => '/api/token/exercise-request/',
      userRequestList: () => '/api/token/exercise-request/',
      request: (tokenId: UrlParam, id: UrlParam) => `/api/token/exercise-request/${tokenId}/${id}/`,
      requestList: (tokenId: UrlParam) => `/api/token/exercise-request/${tokenId}/`,
    },
    payoutList: (tokenId: UrlParam) => `/api/token/payout/${tokenId}/`,
    payoutTierUsers: (payoutTierId: UrlParam) => `/api/token/payout/tier/${payoutTierId}/`,
    payoutById: (tokenId: UrlParam, payoutId: UrlParam) =>
      `/api/token/payout/${tokenId}/${payoutId}/`,
    usersPayoutData: (payoutId: UrlParam) => `/api/token/payout/data/${payoutId}/`,
    userPayouts: (userId: UrlParam) => `/api/token/payout/user/${userId}/`,
    customTokenSearchUsers: (tokenId: UrlParam) =>
      `/api/token/custom-token-user/${tokenId}/search/`,
    customTokenAddUsers: (tokenId: UrlParam) => `/api/token/custom-token-user/${tokenId}/create/`,
    customTokenUserList: (tokenId: UrlParam) => `/api/token/custom-token-user/${tokenId}/`,
    customTokenUser: (tokenId: UrlParam, userId: UrlParam) =>
      `/api/token/custom-token-user/${tokenId}/${userId}/`,
    customTokenUserDistributionUpdate: (tokenId: UrlParam) =>
      `/api/token/custom-token-user/${tokenId}/update/`,
    customTokenPayout: (tokenId: UrlParam) => `/api/token/custom-token-payout/${tokenId}/`,
    customTokenPayoutData: (tokenId: UrlParam) => `/api/token/custom-payout/data/${tokenId}/`,
    customTokenPayoutById: (tokenId: UrlParam, payoutId: UrlParam) =>
      `/api/token/custom-token-payout/${tokenId}/${payoutId}/`,
    customTokenPayoutUsers: (payoutId: UrlParam) => `/api/token/custom-payout/${payoutId}/`,
    customTokenSinglePayout: (tokenId: UrlParam) =>
      `/api/token/custom-token/${tokenId}/single-payout/`,
  },
  auth: {
    login: () => '/api/account/token/',
    tokenRefresh: () => '/api/account/token/refresh/',
    register: () => '/api/account/register/',
    passwordSet: () => '/api/account/password/set/',
    passwordReset: () => '/api/account/password/reset/',
    passwordConfirm: () => '/api/account/password/confirm/',
    resendEmail: () => '/api/account/register/re_send/',
    passwordChange: () => '/api/account/password/change/',
  },
  workspace: {
    list: (userId: UrlParam) => `/api/account/user/workspace/${userId}/`,
    link: () => '/api/account/user/workspace/',
    unlink: () => '/api/account/user/workspace/unlink/',
  },
}
